"use client";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import { PlaceCard } from "./PlaceCard";
import { dangerouslySetInnerHTML } from "@lib/utils";

import { useCallback, useEffect, useRef, useState } from "react";

import { IoSearchOutline } from "react-icons/io5";
import Link from "next/link";
import { GetStaticPropsContext } from "next";
import { getSpotActivities, getTopPlaces } from "@lib/api";
import { config } from "@shared/config";
import Image from "next/image";
import searchPlaceIcon from "../../assets/search-place.png";
import searchActIcon from "../../assets/search-act.png";
import { useTranslation } from "next-i18next";
import router from "next/router";

function SearchBar({
  searchCache,
  searchActCache,
}: {
  searchCache: {
    topPlaces: boolean;
    cityId: number;
    cityName: string;
    countryName: string;
  }[];
  searchActCache: {
    topPlaces: boolean;
    exp: boolean;
    activityName: string;
    experienceId: string;
    cityId: number;
    cityName: string;
    countryName: string;
  }[];
}) {
  const [search, setSearch] = useState('');
  const [searchPlace, setSearchPlace] = useState<any[]>([]);
  const [searchAct, setSearchAct] = useState<any[]>([]);
  const [searchModal, setSearchModal] = useState(false);

  const ref = useRef(null);

  const { t } = useTranslation();

  const topDestinations =[
    { cityId: 23, en: "Barcelona", es: "Barcelona", fr: "Barcelona", country_en: "Spain", country_es: "España", country_fr: "Espagne", topPlaces : true },
    { cityId: 900009, en: "Marrakesh", es: "Marrakech", fr: "Marrakech", country_en: "Morocco", country_es: "Marruecos", country_fr: "Maroc" , topPlaces : true},
    { cityId: 100078, en: "Lisbon", es: "Lisboa", fr: "Lisbonne", country_en: "Portugal", country_es: "Portugal", country_fr: "Portugal" , topPlaces : true},
    { cityId: 100146, en: "New York", es: "Nueva York", fr: "New York", country_en: "United States", country_es: "Estados Unidos", country_fr: "États-Unis" , topPlaces : true },
    { cityId: 29, en: "Madrid", es: "Madrid", fr: "Madrid", country_en: "Spain", country_es: "España", country_fr: "Espagne" },
    { cityId: 300012, en: "Brussels", es: "Bruselas", fr: "Bruxelles", country_en: "Belgium", country_es: "Bélgica", country_fr: "Belgique" },
    { cityId: 900010, en: "Cairo", es: "El Cairo", fr: "Le Caire", country_en: "Egypt", country_es: "Egipto", country_fr: "Égypte" },
    { cityId: 100095, en: "Paris", es: "París", fr: "Paris", country_en: "France", country_es: "Francia", country_fr: "France" },
    { cityId: 281, en: "Budapest", es: "Budapest", fr: "Budapest", country_en: "Hungary", country_es: "Hungría", country_fr: "Hongrie" },
    { cityId: 213, en: "Venice", es: "Venecia", fr: "Venise", country_en: "Italy", country_es: "Italia", country_fr: "Italie" },
    { cityId: 100026, en: "Amsterdam", es: "Ámsterdam", fr: "Amsterdam", country_en: "Netherlands", country_es: "Países Bajos", country_fr: "Pays-Bas" },
    { cityId: 100073, en: "Porto", es: "Oporto", fr: "Porto", country_en: "Portugal", country_es: "Portugal", country_fr: "Portugal" },
    { cityId: 100017, en: "London", es: "Londres", fr: "Londres", country_en: "United Kingdom", country_es: "Reino Unido", country_fr: "Royaume-Uni" },
    { cityId: 212, en: "Rome", es: "Roma", fr: "Rome", country_en: "Italy", country_es: "Italia", country_fr: "Italie" },
    { cityId: 100097, en: "Berlin", es: "Berlín", fr: "Berlin", country_en: "Germany", country_es: "Alemania", country_fr: "Allemagne" },
    { cityId: 898, en: "Milan", es: "Milán", fr: "Milan", country_en: "Italy", country_es: "Italia", country_fr: "Italie" },
    { cityId: 100116, en: "Rio de Janeiro", es: "Río de Janeiro", fr: "Rio de Janeiro", country_en: "Brazil", country_es: "Brasil", country_fr: "Brésil" },
    { cityId: 100133, en: "Mexico City", es: "Ciudad de México", fr: "Mexico", country_en: "Mexico", country_es: "México", country_fr: "Mexique" },
    { cityId: 100138, en: "Tulum", es: "Tulum", fr: "Tulum", country_en: "Mexico", country_es: "México", country_fr: "Mexique" },
    { cityId: 1, en: "Seville", es: "Sevilla", fr: "Séville", country_en: "Spain", country_es: "España", country_fr: "Espagne" },
    { cityId: 33, en: "Valencia", es: "Valencia", fr: "Valence", country_en: "Spain", country_es: "España", country_fr: "Espagne" }
];

function shuffle(arr: any[]) { // randomly rearanges the items in an array
  const result = [];
  for (let i = arr.length-1; i >= 0; i--) {
    // picks an integer between 0 and i:
    const r = Math.floor(Math.random()*(i+1));   // NOTE: use a better RNG if cryptographic security is needed
    // inserts the arr[i] element in the r-th free space in the shuffled array:
    for(let j = 0, k = 0; j <= arr.length-1; j++) {
      if(result[j] === undefined) {
        if(k === r) {
          result[j] = arr[i];    // NOTE: if array contains objects, this doesn't clone them! Use a better clone function instead, if that is needed. 
          break;
        }
        k++;
      }
    }
  }
  return result;
}

const topDestinationsRandom = shuffle(topDestinations);
const topDestinationsRandom10 = topDestinationsRandom.slice(0, 10);

const handleSearch = (event: { target: { value: any; }; }) => {
  const value = event.target.value;

  setSearch(value);

  const MAX_SEARCH_TOP_LENGTH =4;
  const MAX_SEARCH_ACT_LENGTH =4;

  let searchPlaceTmp :any[]=[];
  
  for(let i = 0; i < searchCache.length; i++) {
      if(searchCache[i].cityName.toLocaleLowerCase().indexOf(value.toLowerCase()) !== -1) 
        searchPlaceTmp.push(searchCache[i]);
  }

  let indexAct = 0;

  let searchActTmp :any[]= [];
  
  for(let i =0;i< searchActCache.length; i++) {
    if(searchActCache[i]?.activityName != null && searchActCache[i]?.activityName!.toLocaleLowerCase().indexOf(value.toLowerCase()) !== -1) 
    {
      indexAct = indexAct + 1;
      searchActTmp.push(searchActCache[i]);
      if(indexAct == MAX_SEARCH_ACT_LENGTH)
        break;
    }
    
  }

  searchActTmp.forEach((act) => {
    if(searchPlaceTmp.find( p => p?.cityId == act?.cityId ) == null)
      searchPlaceTmp.push({
        topPlaces: act?.topPlaces!,
        cityId: act?.cityId!,
        cityName: act?.cityName!,
        countryName: act?.countryName!,
      });
  });

  searchPlaceTmp = searchPlaceTmp.slice(0,MAX_SEARCH_TOP_LENGTH);
  setSearchAct(searchActTmp);
  setSearchPlace(searchPlaceTmp);
  


};

const handleClick = () => {
  setSearchModal(true);
};

useEffect(() => {
  const handleOutSideClick = (event: { target: any; }) => {
  console.log('event.target',event.target.className)
    // @ts-ignore
    if (event.target.className.indexOf('searchModal') == -1){
      setSearchModal(false);
    }
  };

  const handleEnterClick = (event: { key: any; }) => {
    console.log('event.key',event.key, searchAct.length)
    if(event.key == "Enter" && searchAct.length > 0)
    {
      const dest = searchAct[0];
      //First Activity:
 


          if(!dest?.topPlaces! && dest?.exp)
          {
            router.push({ pathname: '/spotprofile/' + dest?.cityId  + '/activity/' + dest?.experienceId,
              query: {
                ...router.query,
                expand_activities: true,
                section: 'places-to-visit-search'
              }
            });
          }
          else
          {

              router.push({ pathname: (dest?.topPlaces! ? '/spotprofile/' : '/spotprofiles/' ) + dest?.cityId + (dest?.exp ? '/exp' : '/plc'),
                query: {
                  ...router.query,
                  experience_id: dest?.experienceId
                }
              });
              
          
      
          }



    }
    };

  window.addEventListener("mousedown", handleOutSideClick);
  window.addEventListener("keyup", handleEnterClick);

  return () => {
    window.removeEventListener("mousedown", handleOutSideClick);
    window.removeEventListener("keyup", handleEnterClick);
  };
}, [ref]);




  
  return (
    <>
    <div className="flex flex-row items-center justify-center w-full mt-[-30px] mb-[30px] md:mt-0 md:mb-0 hidden">
       <div className="absolute mr-[80%] md:mr-[47%] grid h-5 !w-5 place-items-center text-white ">
       <IoSearchOutline size={18} />  
         </div>
      <input className="flex flex-col items-center w-[90%] md:w-[50%] h-10 leading-tight border-primary-color border-2 space-y-10 bg-black/60 rounded-3xl text-white
      placeholder-white tracking-tighter text-center hover:placeholder-opacity-0 focus:placeholder-opacity-0 
      
      "
       placeholder={t("SEARCH_PLACEHOLDER")!} 
       value={search}
       onChange={handleSearch}
       onClick={handleClick}
      ref={ref}
      >
      
      </input>
     
    </div>
    <div className={(searchModal ? "" : "hidden" )+ " w-full flex flex-row items-center justify-center mt-5 "}>

      <div
      className={" mt-[415px] md:mt-[250px]  h-[500px] md:h-[270px]   bg-white w-[90%] md:w-[50%] absolute  z-10 rounded "}
      >
        {
          search == ''? 
            <div className="">
              <div className="text-primary-color w-full text-center mt-5 font-bold">
              {t("Top_destinations")}
              </div>
              <div className="grid grid-cols-2 md:grid-cols-5 px-5 py-5 tracking-tighter">
              
                {topDestinationsRandom10.map((dest, idx) => {
                  return (
                    <Link href={(dest.topPlaces ? '/Spotprofile/' : '/Spotprofiles/') + dest.cityId }
                    className="align-left text-left searchModal"
                    >
                      <button className="leading-[1rem] mx-5 my-4 cursor-pointer hover:opacity-70 text-left searchModal"
                      onClick={(e) => {
                              e.stopPropagation();
                            }}
                      >
                        <p className="font-semibold text-md searchModal">{dest.en}</p>
                        <p className="font-normal text-xs opacity-60 searchModal">{dest.country_en}</p>
                      </button>
                    </Link>
                  );
                })}
              </div>
            </div>
            :
            <div className=" flex flex-col md:flex-row  m-auto px-5 py-2 content-center  justify-center">
              <div className="ml-5 md:ml-4 md:w-[20%]">
                <div className="text-primary-color w-full mt-2 font-bold align-center content-center ">
                <div className="flex flex-row content-center align-center m-auto justify-left items-center ml-[-30px] md:ml-[-15px]">
                  <Image src={searchPlaceIcon} alt="Place" width={35} /><p className="ml-2">{t("Destinations")}</p>
                  </div>
                </div>
                <div className="flex flex-col px-2 py-2 tracking-tighter ">
                  {
                    searchPlace.map((dest, idx) => {
                      
                      return (
                        <Link href={(dest?.topPlaces! ? '/Spotprofile/' : '/Spotprofiles/' ) + dest?.cityId }
                        className="align-left text-left searchModal"
                        >
                          <button className="leading-[1rem] md:mx-5 md:my-1 cursor-pointer hover:opacity-70 text-left searchModal"
                          onClick={(e) => {
                                  e.stopPropagation();
                                }}
                          >
                            <p className="font-semibold text-sm md:text-md searchModal">{dest?.cityName}</p>
                            <p className="font-normal text-[10px] md:text-xs opacity-60 searchModal">{dest?.countryName}</p>
                          </button>
                        </Link>
                      
                      );
                    })
                  }
                </div>
              </div>
                <div className="w-full md:w-[80%] md:ml-10 ml-5 ">
                  <div className="text-primary-color w-full mt-2 font-bold">
                    <div className="flex flex-row content-left align-left m-auto justify-left items-center ml-[-30px] md:ml-[-15px]">
                      <Image src={searchActIcon} alt="Activity" width={35} />
                      <p className="ml-2">{t("Activities")}</p>
                    </div>
                  </div>
                  <div className="grid  grid-cols-1 md:grid-cols-2 px-2 md:py-2 tracking-tighter">
                    {
                      //23/exp?experience_id=69
                      //spotprofile/29/activity/5117?expand_activities=true&section=places-to-visit-search
                      searchAct.map((dest, idx) => {
                        
                        return (
                          <Link href={(!dest?.topPlaces! && dest?.exp ? ('/spotprofile/' + dest?.cityId  + '/activity/' + dest?.experienceId + '?expand_activities=true&section=places-to-visit-search') : 
                                                                        (dest?.topPlaces! ? '/spotprofile/' : '/spotprofiles/' ) + dest?.cityId + (dest?.exp ? '/exp' : '/plc') + '?experience_id=' +  dest?.experienceId)}
                          className="align-left text-left searchModal"
                          >
                            <button className="leading-[1rem] md:mx-5 md:my-1 md:my-4 cursor-pointer hover:opacity-70 text-left searchModal"
                            onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                            > 
                              <p className="font-semibold text-sm md:text-md searchModal">{dest?.activityName}</p>
                              <p className="font-normal text-[10px] md:text-xs opacity-60 searchModal">{dest?.exp ? t('Experiences') : t('Places_to_visit') }</p>
                            </button>
                          </Link>
                        
                        );
                      })
                    }
                  </div>
              </div>
           
           
          </div>
            
            

        }
  
     
      </div>

    </div>
    

    </>
  );
}

export default SearchBar;


